<script lang="ts" setup>
const { user } = storeToRefs(useUserStore());

async function logout() {
  useAuthStore().$reset();
  useUserStore().$reset();
  useOnboardingStore().$reset();
  useDiscountStore().$reset();
  useCheckoutStore().$reset();
  useBiTrackingStore().$reset();

  await navigateTo("/account/login");
}
</script>
<template>
  <button v-if="user" class="font-medium text-yz-blue-600" @click="logout">
    {{ $t("user.settings.button.logout") }}
  </button>
</template>
